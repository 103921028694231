exports.components = {
  "component---pages-blog-layout-tsx-content-file-path-pages-blog-index-mdx": () => import("./../../../pages/blog/layout.tsx?__contentFilePath=/opt/build/repo/pages/blog/index.mdx" /* webpackChunkName: "component---pages-blog-layout-tsx-content-file-path-pages-blog-index-mdx" */),
  "component---pages-index-layout-tsx-content-file-path-pages-index-mdx": () => import("./../../../pages/index/layout.tsx?__contentFilePath=/opt/build/repo/pages/index.mdx" /* webpackChunkName: "component---pages-index-layout-tsx-content-file-path-pages-index-mdx" */),
  "component---pages-layout-tsx-content-file-path-pages-blog-another-integration-test-example-in-react-mdx": () => import("./../../../pages/layout.tsx?__contentFilePath=/opt/build/repo/pages/blog/another-integration-test-example-in-react.mdx" /* webpackChunkName: "component---pages-layout-tsx-content-file-path-pages-blog-another-integration-test-example-in-react-mdx" */),
  "component---pages-layout-tsx-content-file-path-pages-blog-being-dogmatic-about-the-clean-architecture-in-react-is-unprofitable-index-mdx": () => import("./../../../pages/layout.tsx?__contentFilePath=/opt/build/repo/pages/blog/being-dogmatic-about-the-clean-architecture-in-react-is-unprofitable/index.mdx" /* webpackChunkName: "component---pages-layout-tsx-content-file-path-pages-blog-being-dogmatic-about-the-clean-architecture-in-react-is-unprofitable-index-mdx" */),
  "component---pages-layout-tsx-content-file-path-pages-blog-good-automated-test-suit-is-critical-to-building-react-apps-fast-index-mdx": () => import("./../../../pages/layout.tsx?__contentFilePath=/opt/build/repo/pages/blog/good-automated-test-suit-is-critical-to-building-react-apps-fast/index.mdx" /* webpackChunkName: "component---pages-layout-tsx-content-file-path-pages-blog-good-automated-test-suit-is-critical-to-building-react-apps-fast-index-mdx" */),
  "component---pages-layout-tsx-content-file-path-pages-blog-in-react-good-developer-experience-is-everything-mdx": () => import("./../../../pages/layout.tsx?__contentFilePath=/opt/build/repo/pages/blog/in-react-good-developer-experience-is-everything.mdx" /* webpackChunkName: "component---pages-layout-tsx-content-file-path-pages-blog-in-react-good-developer-experience-is-everything-mdx" */),
  "component---pages-layout-tsx-content-file-path-pages-blog-react-app-shall-tackle-real-world-scenarios-your-customers-go-through-mdx": () => import("./../../../pages/layout.tsx?__contentFilePath=/opt/build/repo/pages/blog/react-app-shall-tackle-real-world-scenarios-your-customers-go-through.mdx" /* webpackChunkName: "component---pages-layout-tsx-content-file-path-pages-blog-react-app-shall-tackle-real-world-scenarios-your-customers-go-through-mdx" */),
  "component---pages-layout-tsx-content-file-path-pages-sponsorship-index-mdx": () => import("./../../../pages/layout.tsx?__contentFilePath=/opt/build/repo/pages/sponsorship/index.mdx" /* webpackChunkName: "component---pages-layout-tsx-content-file-path-pages-sponsorship-index-mdx" */),
  "component---pages-tdd-react-workshop-for-teams-layout-tsx-content-file-path-pages-tdd-react-workshop-for-teams-index-mdx": () => import("./../../../pages/tdd-react-workshop-for-teams/layout.tsx?__contentFilePath=/opt/build/repo/pages/tdd-react-workshop-for-teams/index.mdx" /* webpackChunkName: "component---pages-tdd-react-workshop-for-teams-layout-tsx-content-file-path-pages-tdd-react-workshop-for-teams-index-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

